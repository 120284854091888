import { useTranslate } from '/machinery/I18n'
import {
  ButtonIconWithLabel,
  ButtonIconWithLabelTransparent,
  ButtonIconWithLabelBlue,
  ButtonIconWithLabelGray,
} from '/features/buildingBlocks/ButtonIcon'

import styles from './SkillsMatchButton.css'

import plusIcon from '/images/icons/plus.raw.svg'
import checkIcon from '/images/icons/check.raw.svg'
import refreshIcon from '/images/icons/refresh.raw.svg'
import crossIcon from '/images/icons/cross.raw.svg'

export function SkillsMatchButtonToggle({
  id,
  label,
  onClick,
  isActive,
  isProcessing = false,
  ariaControls = undefined,
}) {
  const { __ } = useTranslate()

  return (
    <div className={styles.componentToggle}>
      <ButtonIconWithLabel
        disabled={isProcessing}
        ariaLabel={isActive ? __({ skill: label })`remove-skill-x` : __({ skill: label })`add-skill-x`}
        dataX={`${isActive ? 'remove-skill' : 'add-skill'}-${id}`}
        icon={isActive ? checkIcon : plusIcon}
        {...{ onClick, label, isActive, isProcessing, ariaControls }}
      />
    </div>
  )
}

export function SkillsMatchButtonToggleTransparent({
  id,
  label,
  onClick,
  isActive,
  subjectName,
  isProcessing = false,
  ariaControls = undefined,
}) {
  const { __ } = useTranslate()

  return (
    <div className={styles.componentToggleTransparent}>
      <ButtonIconWithLabelTransparent
        disabled={isProcessing}
        ariaLabel={__({ interest: subjectName })`${isActive ? 'remove-interest-x' : 'add-interest-x'}`}
        dataX={`${isActive ? 'remove-proof-point' : 'add-proof-point'}-${id}`}
        icon={isActive ? checkIcon : plusIcon}
        {...{ onClick, label, ariaControls, isActive, isProcessing }}
      />
    </div>
  )
}

export function SkillsMatchButtonSelected({ onClick, label, isProcessing = false }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.componentSelected}>
      <ButtonIconWithLabelBlue
        disabled={isProcessing}
        ariaLabel={__({ skill: label })`remove-skill-x`}
        dataX='remove-item'
        icon={crossIcon}
        {...{ onClick, label, isProcessing }}
      />
    </div>
  )
}

export function SkillsMatchButtonRefresh({ onClick, label }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.componentRefresh}>
      <ButtonIconWithLabelGray
        dataX='new-suggestions'
        ariaLabel={__`refresh-skills-match`}
        icon={refreshIcon}
        {...{ onClick, label }}
      />
    </div>
  )
}
